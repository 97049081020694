@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
  body::-webkit-scrollbar-track {
    background: #0D0E0F;        /* color of the tracking area */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: rgb(90, 0, 235);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #0D0E0F;  /* creates padding around scroll thumb */
  }


.custom-shape-divider-bottom-1640788416 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1640799742 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1640799742 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 80px;
}

.custom-shape-divider-bottom-1640799742 .shape-fill {
    fill: #0D0E0F;
}

.custom-shape-divider-bottom-1640960853 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1641137260 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1641137260 svg {
  position: relative;
  display: block;
  width: calc(159% + 1.3px);
  height: 200px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}

html {
    scroll-behavior: smooth;
    background-color: #0D0E0F;
}